<template>
  <div class="share-trips-sidebar">
    <div class="share-trips-sidebar--content">
      {{
        `Are you sure you would like to ${
          isUnshare ? 'unshare' : 'share'
        } all quotes and reservations for this contact?`
      }}
    </div>
    <div class="cr-sidebar-dialog--button-spacer"></div>
    <div>
      <CRButton
        id="share-trips-save-btn"
        class="share-trips-sidebar--action-btn"
        :color="isUnshare ? 'red' : 'primary'"
        @click="submit"
      >
        {{ `${isUnshare ? 'Unshare' : 'Share'} All` }}
      </CRButton>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { deepClone } from '@/utils/deepClone'
import SelectedOperatorDetail from './SelectedOperatorDetail.vue'
import { shareQuote, unshareQuote } from '@/services/quotes'
import { shareReservation, unshareReservation } from '@/services/reservations'
import { EventBus } from '@/utils/event-bus'

export default {
  components: {
    SelectedOperatorDetail,
  },
  props: {
    sharedTrip: {
      type: Object,
      required: true,
    },
    isUnshare: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    async submit() {
      try {
        if (this.isUnshare) {
          for (const quoteId of this.sharedTrip.quoteIds) {
            await unshareQuote(quoteId, this.sharedTrip.userId)
          }
          for (const reservationId of this.sharedTrip.reservationIds) {
            await unshareReservation(reservationId, this.sharedTrip.userId)
          }
        } else {
          await Promise.all([
            ...this.sharedTrip.quoteIds.map(async (quoteId) => {
              await shareQuote(quoteId, this.sharedTrip.userId)
            }),
            ...this.sharedTrip.reservationIds.map(async (reservationId) => {
              await shareReservation(reservationId, this.sharedTrip.userId)
            }),
          ])
        }
        EventBus.$emit('global-table-view-refresh')
        this.$nextTick(() => {
          this.showAlert({
            type: 'success',
            message: `Successfully ${
              this.isUnshare ? 'unshared' : 'shared'
            } all quotes and reservations`,
          })
        })
        this.close()
      } catch (e) {
        console.error(e)
        this.showAlert({
          type: 'error',
          message: `Error ${
            this.isUnshare ? 'unsharing' : 'sharing'
          } all quotes and reservations`,
        })
      }
    },
    close() {
      this.$store.dispatch('app/closeSidebarDialog')
    },
  },
}
</script>

<style lang="scss" scoped>
.share-trips-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 20px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }
}

::v-deep .cr-sidebar-dialog::-webkit-scrollbar {
  display: none;
}
</style>
